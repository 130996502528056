import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './assets/css/App.css';

import App from './App';
import {Toaster} from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <App/>
        <Toaster
            position="bottom-right"
            reverseOrder={false} 
        />
    </BrowserRouter>, 
);
